import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { Box } from "@mui/material";
import Navbar from './layout/Navbar';
import Footer from './layout/Footer';
import HomePage from './pages/HomePage';
import GamePage from './pages/GamePage';
import ContactPage from './pages/ContactPage';
import AboutPage from './pages/AboutPage';
import PolicyPage from './pages/PolicyPage';
import theme from './theme/theme';
import AgeVerificationModal from './components/AgeVerificationModal';
import Roulette from './components/RouletteGame';
import CardGame from "./components/CardGame";
import CookieConsent from './components/CookieConsent';

function App() {
    return (
        <ThemeProvider theme={theme}>
            <Router>
                <AgeVerificationModal>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minHeight: '100vh',
                        backgroundColor: theme.palette.background.default
                    }}>
                        <CookieConsent />

                        <Navbar />

                        <Box component="main" sx={{ flex: 1 }}>
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/game" element={<GamePage />} />
                                <Route path="/aboutus" element={<AboutPage />} />
                                <Route path="/contactus" element={<ContactPage />} />
                                <Route path="/policy" element={<PolicyPage />} />
                                <Route path="/roulette-game" element={<Roulette />} />
                                <Route path="/card-game" element={<CardGame />} />
                            </Routes>
                        </Box>

                        <Footer />
                    </Box>
                </AgeVerificationModal>
            </Router>
        </ThemeProvider>
    );
}

export default App;
