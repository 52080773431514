import { SxProps, Theme } from '@mui/material';

export const navbarStyles = {
    appBar: {
        bgcolor: "#0F172A",
        px: 3,
        boxShadow: 3
    } as SxProps<Theme>,

    toolbar: {
        justifyContent: "space-between"
    } as SxProps<Theme>,

    logoContainer: {
        textDecoration: 'none',
        color: 'inherit',
        display: 'flex',
        alignItems: 'center',
        gap: 1
    } as SxProps<Theme>,

    logoIcon: {
        fontSize: 40,
        color: '#00FFA5',
        mr: 1,
        filter: 'drop-shadow(0 0 8px rgba(0, 255, 165, 0.4))'
    } as SxProps<Theme>,

    logoText: {
        color: '#00FFA5',
        fontWeight: 'bold',
        fontSize: '1.5rem',
        cursor: 'pointer'
    } as SxProps<Theme>,

    desktopMenu: {
        display: { xs: "none", md: "flex" },
        gap: 3
    } as SxProps<Theme>,

    navButton: (isActive: boolean) => ({
        color: "#94A3B8",
        fontSize: "1rem",
        "&:hover": {
            color: "#00FFA5",
            backgroundColor: 'transparent'
        },
        fontWeight: isActive ? "bold" : "normal",
        borderBottom: isActive ? '2px solid #00FFA5' : 'none',
        transition: 'all 0.3s ease'
    }) as SxProps<Theme>,

    mobileMenuButton: {
        display: { md: "none" },
        color: "#00FFA5",
        '&:hover': {
            backgroundColor: 'rgba(0, 255, 165, 0.1)'
        }
    } as SxProps<Theme>,

    mobileDrawer: {
        '& .MuiDrawer-paper': {
            bgcolor: '#1E293B'
        }
    } as SxProps<Theme>,

    mobileList: {
        width: 250,
        height: "100%"
    } as SxProps<Theme>,

    mobileListItem: {
        textDecoration: 'none',
        color: '#94A3B8',
        width: '100%',
        px: 3,
        py: 2,
        '&:hover': {
            backgroundColor: 'rgba(255, 255, 255, 0.05)',
            color: '#00FFA5'
        }
    } as SxProps<Theme>,

    mobileMenuItemText: {
        fontSize: "1.1rem",
        fontWeight: "bold",
        transition: 'all 0.3s ease'
    } as SxProps<Theme>
};