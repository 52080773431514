import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppBar, Toolbar, Button, IconButton, Drawer, List, ListItem, Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { SportsEsports } from "@mui/icons-material";
import { navbarStyles } from "../styles/Navbar.styles";

const Navbar = () => {
    const [mobileOpen, setMobileOpen] = useState(false);
    const location = useLocation();

    const navItems = [
        { label: "Home", href: "/"},
        { label: "Games", href: "/game" },
        { label: "About Us", href: "/aboutus" },
        { label: "Contact Us", href: "/contactus" },
        { label: "Policy", href: "/policy" },
    ];

    return (
        <>
            <AppBar position="sticky" sx={navbarStyles.appBar}>
                <Toolbar sx={navbarStyles.toolbar}>

                    <Box component={Link} to="/" sx={navbarStyles.logoContainer}>
                        <SportsEsports sx={navbarStyles.logoIcon} />
                        <Box component="span" sx={navbarStyles.logoText}>
                            Lucky Change
                        </Box>
                    </Box>


                    <Box sx={navbarStyles.desktopMenu}>
                        {navItems.map((item) => (
                            <Button
                                key={item.label}
                                component={Link}
                                to={item.href}
                                sx={navbarStyles.navButton(location.pathname === item.href)}
                            >
                                {item.label}
                            </Button>
                        ))}
                    </Box>


                    <IconButton
                        edge="end"
                        sx={navbarStyles.mobileMenuButton}
                        onClick={() => setMobileOpen(true)}
                    >
                        <MenuIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>


            <Drawer
                anchor="right"
                open={mobileOpen}
                onClose={() => setMobileOpen(false)}
                sx={navbarStyles.mobileDrawer}
            >
                <List sx={navbarStyles.mobileList}>
                    {navItems.map((item) => (
                        <ListItem key={item.label} disablePadding>
                            <Box
                                component={Link}
                                to={item.href}
                                sx={navbarStyles.mobileListItem}
                                onClick={() => setMobileOpen(false)}
                            >
                                <Box sx={navbarStyles.mobileMenuItemText}>
                                    {item.label}
                                </Box>
                            </Box>
                        </ListItem>
                    ))}
                </List>
            </Drawer>
        </>
    );
};

export default Navbar;