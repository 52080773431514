import { Container, Grid, Typography, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../styles/AboutPage.css';

const AboutPage = () => {
    const navigate = useNavigate();

    const handleStartPlaying = () => {
        navigate('/game');
    };

    return (
        <Box className="aboutPage">

            <Grid container spacing={0} className="heroSection">

                <Grid item xs={12} className="heroBackground" />

                <Grid item xs={12} className="heroContent">
                    <Typography variant="h1" className="title">
                        About Lucky Change
                    </Typography>
                </Grid>
            </Grid>


            <Container maxWidth="lg" className="contentContainer">
                <Grid container spacing={6}>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" className="sectionTitle">
                            Our Mission
                        </Typography>
                        <Typography variant="body1" className="sectionText">
                            Lucky Change is your premier destination for free social casino games, offering an electrifying
                            space where entertainment intersects with innovation and community spirit.
                        </Typography>

                        <Typography variant="h3" className="sectionTitle">
                            Game Variety
                        </Typography>
                        <Typography variant="body1" className="sectionText">
                            Dive into our extensive collection of games, each crafted to deliver unique challenges
                            and unforgettable experiences. Whether you're seeking relaxation or excitement,
                            our diverse selection caters to all preferences.
                        </Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h3" className="sectionTitle">
                            Community Focus
                        </Typography>
                        <Typography variant="body1" className="sectionText">
                            At Lucky Change, we're building a global community of gaming enthusiasts.
                            Connect with players worldwide through live chats, tournaments, and collaborative events.
                        </Typography>

                        <Typography variant="h3" className="sectionTitle">
                            Safety & Innovation
                        </Typography>
                        <Typography variant="body1" className="sectionText">
                            We prioritize your security with cutting-edge protection measures while constantly
                            innovating our platform. Enjoy regular content updates and feature enhancements
                            that keep the experience fresh.
                        </Typography>
                    </Grid>
                </Grid>


                <Box className="ctaContainer">
                    <Button className="ctaButton" onClick={handleStartPlaying}>
                        Join the Fun
                    </Button>
                </Box>
            </Container>
        </Box>
    );
};

export default AboutPage;
