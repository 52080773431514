import { SxProps, Theme } from '@mui/material';

export const footerStyles = {
    root: {
        bgcolor: '#0F172A',
        color: '#94A3B8',
        py: 8,
        borderTop: '1px solid',
        borderColor: 'rgba(0, 255, 165, 0.1)',
        mt: 'auto'
    } as SxProps<Theme>,

    logoContainer: {
        display: 'flex',
        alignItems: 'center',
        mb: 2
    } as SxProps<Theme>,

    logoIcon: {
        fontSize: 40,
        color: '#00FFA5',
        mr: 1,
        filter: 'drop-shadow(0 0 8px rgba(0, 255, 165, 0.4))'
    } as SxProps<Theme>,

    logoText: {
        color: '#00FFA5',
        fontWeight: 'bold'
    } as SxProps<Theme>,

    description: {
        lineHeight: 1.6
    } as SxProps<Theme>,

    sectionTitle: {
        color: '#00FFA5',
        mb: 2,
        textTransform: 'uppercase',
        letterSpacing: 1
    } as SxProps<Theme>,

    navLink: {
        display: 'block',
        mb: 1,
        '&:hover': {
            color: '#00FFA5'
        },
        transition: 'color 0.3s'
    } as SxProps<Theme>,

    socialContainer: {
        display: 'flex',
        gap: 3
    } as SxProps<Theme>,

    socialIcon: {
        color: '#94A3B8',
        '&:hover': {
            color: '#00FFA5',
            transform: 'translateY(-3px)',
            transition: 'all 0.3s'
        }
    } as SxProps<Theme>,

    bottomSection: {
        borderTop: '1px solid',
        borderColor: 'rgba(255, 255, 255, 0.1)',
        mt: 6,
        pt: 4,
        textAlign: 'center'
    } as SxProps<Theme>,

    copyrightText: {
        fontSize: '0.875rem'
    } as SxProps<Theme>,

    separator: {
        mx: 1,
        color: '#00FFA5'
    } as SxProps<Theme>
};