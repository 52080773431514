import { Container, Typography, Box } from '@mui/material';
import '../styles/PolicyPage.css';

const PrivacyPolicy = () => {
    return (
        <Box className="privacy-policy-page">
            <Container maxWidth="lg" className="content-container">

                <Box className="header">
                    <Typography variant="h1" className="title">
                        Privacy Policy for Lucky Change
                    </Typography>
                    <Typography variant="h4" className="subtitle">
                        Protecting Your Privacy and Ensuring Your Trust
                    </Typography>
                </Box>


                <Box className="content">
                    <Typography paragraph>
                        At Lucky Change, we take your privacy seriously. Our commitment is to keep your personal information safe and secure.
                    </Typography>

                    <Typography variant="h3" className="section-title">Data Collection</Typography>
                    <Typography paragraph>
                        We collect only the data necessary to provide you with a seamless gaming experience. This may include your name, email address, and preferences.
                    </Typography>

                    <Typography variant="h3" className="section-title">Security Measures</Typography>
                    <Typography paragraph>
                        Your information is protected using advanced security measures to ensure its confidentiality and prevent unauthorized access.
                    </Typography>

                    <Typography variant="h3" className="section-title">Data Sharing</Typography>
                    <Typography paragraph>
                        Lucky Change never shares your personal data with third parties, except when required for service delivery or legal obligations. Any sharing is handled responsibly.
                    </Typography>

                    <Typography variant="h3" className="section-title">Cookies & Technologies</Typography>
                    <Typography paragraph>
                        Cookies and other technologies are used to improve your experience on the platform. You can control your cookie preferences through your browser settings.
                    </Typography>

                    <Typography variant="h3" className="section-title">Your Control</Typography>
                    <Typography paragraph>
                        You have full control over your data. Contact us at any time to access, update, or delete your information.
                    </Typography>

                    <Typography variant="h3" className="section-title">Age Requirements</Typography>
                    <Typography paragraph>
                        Lucky Change is intended for users who meet local age requirements. We take steps to ensure compliance with these rules.
                    </Typography>

                    <Typography variant="h3" className="section-title">Agreement</Typography>
                    <Typography paragraph sx={{ mb: 4 }}>
                        By using Lucky Change, you agree to this Privacy Policy. For questions or concerns, reach out to our support team via the provided channels.
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default PrivacyPolicy;
