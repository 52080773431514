import { Container, Grid, Typography, Button, Box } from '@mui/material';
import NewsCard from '../components/NewsCard';
import Leaderboard from '../components/LeaderBoard';
import { useNavigate } from 'react-router-dom';

const CharityChallengeCard = ({ title, difficulty, prizePool, participants }:
                                  { title: string; difficulty: string; prizePool: string; participants: string }) => (
    <Box sx={{ bgcolor: '#1E293B', p: 3, borderRadius: 2, color: 'white' }}>
        <Typography variant="h6" mb={1}>{title}</Typography>
        <Typography variant="body2" color="#94A3B8">Difficulty: {difficulty}</Typography>
        <Typography variant="body2" color="#94A3B8">Prize: {prizePool}</Typography>
        <Typography variant="body2" color="#94A3B8">Participants: {participants}</Typography>

    </Box>
);

const HomePage = () => {
    const navigate = useNavigate();

    return (
        <Box sx={{ bgcolor: '#0F172A', minHeight: '100vh' }}>

            <Box sx={{
                height: '100vh',
                pt: '50px',
                position: 'relative',
                background: `linear-gradient(to right, rgba(0,0,0,0.9) 30%, transparent 70%), 
                   url("/assets/homePageBackground.png") center/cover`
            }}>
                <Box sx={{ position: 'relative', zIndex: 2, ml: 8, mt:22, color: 'white' }}>
                    <Typography variant="h1" color="#00FFA5" fontWeight="bold" mb={3}>
                        Charity Gaming Hub
                    </Typography>
                    <Typography variant="h5" color="#94A3B8" mb={4}>
                        Play Smart. Earn Points. Donate Better.
                    </Typography>
                    <Button
                        variant="contained"
                        onClick={() => navigate('/game')}
                        sx={{
                            bgcolor: '#00FFA5',
                            color: '#0F172A',
                            '&:hover': { bgcolor: '#00E694', transform: 'scale(1.1)' },
                            px: 6,
                            py: 2,
                            borderRadius: '50px',
                            fontWeight: 'bold',
                            transition: 'all 0.3s ease'
                        }}
                    >
                        Start Playing
                    </Button>
                </Box>
            </Box>

            <Container sx={{ py: 8 }}>
                <Typography variant="h3" color="white" mb={4}>Popular Challenges</Typography>
                <Grid container spacing={4} mb={8}>
                    {[1,2,3,4,5,6].map((item) => (
                        <Grid item xs={12} md={4} key={item}>
                            <CharityChallengeCard
                                title={`Challenge #${item}`}
                                difficulty={['Easy','Medium','Hard'][item%3]}
                                prizePool="$25,000"
                                participants="1,234 Players"
                            />
                        </Grid>
                    ))}
                </Grid>

                <Grid container spacing={6}>
                    <Grid item xs={12} md={8}>
                        <Typography variant="h4" color="white" mb={4}>Latest Updates</Typography>
                        {[1,2].map((item) => (
                            <NewsCard
                                key={item}
                                title={`New Partner (Week ${item})`}
                                date={`2024-03-${10 + item}`}
                                excerpt="Support our new charity partners!"
                            />
                        ))}
                    </Grid>

                    <Grid item xs={12} md={4}>
                        <Leaderboard
                            title="Top Contributors"
                            players={[
                                { name: 'Player1', score: 24500 },
                                { name: 'CharityKing', score: 19800 },
                                { name: 'HopeWarrior', score: 15600 }
                            ]}
                        />
                    </Grid>
                </Grid>

                <Grid sx={{ bgcolor: '#1E293B', p: 4, borderRadius: 2, color: 'white', mt: 6 }}>
                    <Typography variant="h5" color="#00FFA5" mb={2} fontWeight="bold">Disclaimer</Typography>
                    <Typography variant="body1" color="#94A3B8">
                        This platform offers social casino-style games for players aged 18 and older, designed to provide a fun and immersive experience.
                        These games are intended solely for entertainment, with no opportunities to win real money, prizes, or goods. Any in-game rewards or
                        virtual currency earned or purchased are purely fictional and hold no monetary value. Achievements or progress within these games do
                        not translate to real-money gambling success. The games offer a simulated casino experience without any financial risk, ensuring a safe
                        and enjoyable environment for casual play. By using this site, you acknowledge that it is intended for recreational purposes only. We
                        promote responsible gaming and encourage users to play within their limits. If you feel your gaming habits are becoming problematic,
                        we recommend seeking support from responsible gaming organizations. For entertainment only. Play responsibly.
                    </Typography>
                </Grid>
            </Container>
        </Box>
    );
};

export default HomePage;
