import { Card, CardContent, Typography, List, ListItem, ListItemText } from '@mui/material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

interface Player {
    name: string;
    score: number;
}

interface LeaderboardProps {
    title: string;
    players: Player[];
}

const Leaderboard = ({ title, players }: LeaderboardProps) => {
    return (
        <Card sx={{
            bgcolor: '#1E293B',
            borderRadius: 3,
            boxShadow: '0 4px 20px rgba(0, 255, 165, 0.1)'
        }}>
            <CardContent>
                <Typography variant="h5" sx={{
                    color: '#FFFFFF',
                    mb: 2,
                    display: 'flex',
                    alignItems: 'center',
                    gap: 1
                }}>
                    <EmojiEventsIcon sx={{ color: '#FFD700', fontSize: 30 }} />
                    {title}
                </Typography>

                <List>
                    {players.map((player, index) => (
                        <ListItem
                            key={player.name}
                            sx={{
                                bgcolor: index % 2 === 0 ? 'rgba(255, 255, 255, 0.05)' : 'transparent',
                                borderRadius: 2,
                                mb: 0.5
                            }}
                        >
                            <ListItemText
                                primary={`${index + 1}. ${player.name}`}
                                secondary={`${player.score.toLocaleString()} Points`}
                                primaryTypographyProps={{
                                    sx: {
                                        color: '#FFFFFF',
                                        fontWeight: index === 0 ? 'bold' : 'normal'
                                    }
                                }}
                                secondaryTypographyProps={{
                                    sx: {
                                        color: '#64748B',
                                        fontSize: '0.875rem'
                                    }
                                }}
                            />
                        </ListItem>
                    ))}
                </List>
            </CardContent>
        </Card>
    );
};

export default Leaderboard;