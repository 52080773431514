import { Container, Grid, Typography, Button, Box, TextField } from '@mui/material';
import '../styles/ContactPage.css';

const ContactPage = () => {
    return (
        <Box className="contact-page">
            <Container maxWidth="md">

                <Box className="header">
                    <Typography variant="h1" className="title">
                        Connect with Lucky Change
                    </Typography>
                    <Typography variant="h5" className="subtitle">
                        We're here to help! Reach out to us for support, feedback, or inquiries—your voice matters.
                    </Typography>
                </Box>


                <Box component="form" className="form-container">
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                required
                                label="Name"
                                variant="outlined"
                                className="input-field"
                                placeholder="Your Name"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                required
                                label="Surname"
                                variant="outlined"
                                className="input-field"
                                placeholder="Your Surname"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                required
                                type="email"
                                label="Email"
                                variant="outlined"
                                className="input-field"
                                placeholder="Your Email"
                            />
                        </Grid>

                        <Grid item xs={12} md={6}>
                            <TextField
                                fullWidth
                                label="Phone"
                                variant="outlined"
                                className="input-field"
                                placeholder="Your Phone Number"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                label="Address"
                                variant="outlined"
                                className="input-field"
                                placeholder="Your Address"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <TextField
                                fullWidth
                                required
                                label="Message"
                                variant="outlined"
                                multiline
                                rows={4}
                                className="input-field"
                                placeholder="Your Message"
                            />
                        </Grid>

                        <Grid item xs={12} className="cta-container">
                            <Button
                                type="submit"
                                variant="contained"
                                className="cta-button"
                            >
                                Send Message
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default ContactPage;
