import { Card, CardContent, Typography, Stack, Avatar } from '@mui/material';
import ArticleIcon from '@mui/icons-material/Article';

interface NewsCardProps {
    title: string;
    date: string;
    excerpt: string;
}

const NewsCard = ({ title, date, excerpt } : NewsCardProps) => {
    return (
        <Card sx={{
            bgcolor: '#1E293B',
            mb: 2,
            borderRadius: 3,
            '&:hover': {
                bgcolor: '#1E293BCC'
            }
        }}>
            <CardContent>
                <Stack direction="row" spacing={2} alignItems="center">
                    <Avatar sx={{ bgcolor: '#00FFA520', width: 48, height: 48 }}>
                        <ArticleIcon sx={{ color: '#00FFA5' }} />
                    </Avatar>
                    <div>
                        <Typography variant="h6" sx={{ color: '#FFFFFF' }}>
                            {title}
                        </Typography>
                        <Typography variant="caption" sx={{ color: '#64748B' }}>
                            {date}
                        </Typography>
                        <Typography variant="body2" sx={{ color: '#94A3B8', mt: 1 }}>
                            {excerpt}
                        </Typography>
                    </div>
                </Stack>
            </CardContent>
        </Card>
    );
};

export default NewsCard;