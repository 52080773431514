import { useState, useEffect } from 'react';
import { Box, Button, Typography } from '@mui/material';

const CookieConsent = () => {
    const [isCookieAccepted, setIsCookieAccepted] = useState(false);


    useEffect(() => {
        const cookieConsent = localStorage.getItem('cookieConsent');
        if (cookieConsent === 'true') {
            setIsCookieAccepted(true);
        }
    }, []);


    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'true');
        setIsCookieAccepted(true);
    };

    if (isCookieAccepted) return null;

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                bgcolor: '#1E293B',
                color: 'white',
                p: 2,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                zIndex: 1000,
            }}
        >
            <Typography variant="body1" color="#94A3B8">
                This site uses cookies to improve your experience. By continuing, you agree to our use of cookies.
            </Typography>
            <Button
                variant="contained"
                onClick={handleAccept}
                sx={{
                    bgcolor: '#00FFA5',
                    color: '#0F172A',
                    '&:hover': { bgcolor: '#00E694' },
                    fontWeight: 'bold',
                    borderRadius: '50px',
                    px: 4,
                }}
            >
                Accept Cookies
            </Button>
        </Box>
    );
};

export default CookieConsent;
