import { Box, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import '../styles/GamePage.css';

const GamePage = () => {
    const navigate = useNavigate();

    return (
        <Box className="game-page">
            <Typography className="game-title">Games</Typography>

            <Box className="game-cards">
                <Box className="game-card" onClick={() => navigate('/card-game')}>
                    <img src="/assets/MatchGame.png" alt="Memory Game" />
                    <Typography variant="h3">Memory Game</Typography>
                </Box>

                <Box className="game-card" onClick={() => navigate('/roulette-game')}>
                    <img src="/assets/Roulette.png" alt="Roulette Game" />
                    <Typography variant="h3">Roulette</Typography>
                </Box>

            </Box>
        </Box>
    );
};

export default GamePage;
