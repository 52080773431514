import { useState } from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Box,
    Typography
} from '@mui/material';
import { ReactNode } from 'react';
import '../styles/AgeVerificationModal.css';

interface AgeVerificationModalProps {
    children: ReactNode;
}

const AgeVerificationModal = ({ children }: AgeVerificationModalProps) => {
    const [open, setOpen] = useState(true);
    const [accessGranted, setAccessGranted] = useState(false);
    const [blocked, setBlocked] = useState(false);

    const handleClose = (granted: boolean) => {
        setOpen(false);
        setAccessGranted(granted);
        if (!granted) setBlocked(true);
    };

    return (
        <>
            <Dialog
                open={open}
                aria-labelledby="age-verification-title"
                className="age-dialog"
            >
                <DialogTitle
                    id="age-verification-title"
                    className="age-dialog-title"
                >
                    ⚠️ Age Verification
                </DialogTitle>

                <DialogContent>
                    <DialogContentText className="age-dialog-content-text">
                        Are you over 18 years old?
                    </DialogContentText>
                </DialogContent>

                <DialogActions className="age-dialog-actions">
                    <Button
                        onClick={() => handleClose(true)}
                        variant="contained"
                        className="age-yes-button"
                    >
                        Yes
                    </Button>
                    <Button
                        onClick={() => handleClose(false)}
                        variant="outlined"
                        className="age-no-button"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>

            {blocked && (
                <Box className="age-blocked-container">
                    <Typography variant="h3" className="age-blocked-title">
                        ⛔ Access Denied
                    </Typography>
                    <Typography variant="h5" className="age-blocked-text">
                        You are not allowed to access the site due to age restrictions
                    </Typography>
                </Box>
            )}

            {!blocked && accessGranted && children}
        </>
    );
};

export default AgeVerificationModal;