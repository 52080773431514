import { useState, useEffect } from 'react';
import { Grid, Box, Typography, Button } from '@mui/material';

type Card = {
    id: number;
    value: string;
    isFlipped: boolean;
    isMatched: boolean;
};

const EMOJI_SET = ['🎮', '🎲', '🎯', '🎨', '🎪', '🎭', '🎫', '🎰'];
const CARD_PAIRS = 8;

const CardGame = () => {
    const [cards, setCards] = useState<Card[]>([]);
    const [selectedCards, setSelectedCards] = useState<number[]>([]);
    const [moves, setMoves] = useState(0);
    const [gameWon, setGameWon] = useState(false);

    const initializeGame = () => {
        const symbols = [...EMOJI_SET].slice(0, CARD_PAIRS).flatMap(s => [s, s]);
        const shuffled = [...symbols].sort(() => Math.random() - 0.5);

        setCards(shuffled.map((value, id) => ({
            id,
            value,
            isFlipped: false,
            isMatched: false,
        })));
        setMoves(0);
        setGameWon(false);
    };

    useEffect(() => {
        initializeGame();
    }, []);

    useEffect(() => {
        if (selectedCards.length === 2) {
            const [first, second] = selectedCards;
            const match = cards[first].value === cards[second].value;

            setTimeout(() => {
                setCards(cards.map(card => {
                    if (card.id === first || card.id === second) {
                        return { ...card, isFlipped: false, isMatched: match };
                    }
                    return card;
                }));
                setSelectedCards([]);
                setMoves(m => m + 1);
            }, 1000);
        }
    }, [selectedCards]);

    const handleCardClick = (id: number) => {
        if (
            !cards[id].isMatched &&
            selectedCards.length < 2 &&
            !selectedCards.includes(id)
        ) {
            setSelectedCards([...selectedCards, id]);
            setCards(cards.map(card =>
                card.id === id ? { ...card, isFlipped: true } : card
            ));
        }
    };

    useEffect(() => {
        if (cards.length > 0 && cards.every(c => c.isMatched)) {
            setGameWon(true);
        }
    }, [cards]);

    return (
        <Box sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
            width: '100%',
            bgcolor: '#0D1B2A',
        }}>
            <Box sx={{
                width: '100%',
                maxWidth: '1200px',
                padding: 3,
                textAlign: 'center'
            }}>
                <Typography variant="h4" sx={{
                    mb: 4,
                    fontSize: { xs: '1.5rem', md: '2rem' },
                    color: '#00E676'
                }}>
                    Moves: {moves} | Matched Pairs: {cards.filter(c => c.isMatched).length / 2}
                </Typography>

                <Grid container spacing={2} sx={{
                    margin: '0 auto',
                    justifyContent: 'center',
                }}>
                    {cards.map(card => (
                        <Grid item key={card.id} xs={4} sm={3} md={2}>
                            <Box
                                onClick={() => handleCardClick(card.id)}
                                sx={{
                                    height: { xs: 80, sm: 100, md: 120 },
                                    bgcolor: card.isMatched ? '#00E676' : '#1B263B',
                                    borderRadius: 3,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    fontSize: { xs: '2rem', md: '3rem' },
                                    transition: 'transform 0.5s ease, box-shadow 0.3s ease',
                                    transform: `rotateY(${card.isFlipped || card.isMatched ? 180 : 0}deg)`,
                                    boxShadow: '0px 4px 10px rgba(0, 255, 165, 0.5)',
                                    '&:hover': {
                                        transform: card.isMatched ? 'none' : 'scale(1.1)',
                                    },
                                }}
                            >
                                <Typography sx={{
                                    color: card.isFlipped || card.isMatched ? (card.isMatched ? '#0F172A' : '#FFFFFF') : 'goldenrod',
                                    transform: `rotateY(${card.isFlipped || card.isMatched ? 0 : 180}deg)`,
                                    fontSize: 'inherit'
                                }}>
                                    {card.isFlipped || card.isMatched ? card.value : '?'}
                                </Typography>

                            </Box>
                        </Grid>
                    ))}
                </Grid>

                {gameWon && (
                    <Box sx={{ mt: 4, textAlign: 'center' }}>
                        <Typography variant="h4" sx={{ color: '#00E676', mb: 2 }}>
                            Congratulations! 🎉
                        </Typography>
                        <Button
                            variant="contained"
                            sx={{
                                bgcolor: 'linear-gradient(45deg, #00FFA5, #00E676)',
                                color: '#0F172A',
                                '&:hover': { bgcolor: '#00E694' },
                                borderRadius: '50px',
                                px: 4,
                                py: 1
                            }}
                            onClick={initializeGame}
                        >
                            Play Again
                        </Button>
                    </Box>
                )}

                <Box sx={{ mt: 4, textAlign: 'center' }}>
                    <Button
                        variant="outlined"
                        sx={{
                            color: '#00E676',
                            borderColor: '#00E676',
                            '&:hover': { borderColor: '#00E694' },
                            borderRadius: '50px',
                            px: 4,
                            py: 1
                        }}
                        onClick={initializeGame}
                    >
                        Reset Game
                    </Button>
                </Box>
            </Box>
        </Box>
    );
};

export default CardGame;
