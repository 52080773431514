import { Box, Container, Grid, Typography, Link, IconButton } from '@mui/material';
import { Facebook, Twitter, Instagram, SportsEsports } from '@mui/icons-material';
import { footerStyles } from '../styles/Footer.styles';
import { Link as RouterLink } from 'react-router-dom';

const Footer = () => {
    return (
        <Box component="footer" sx={footerStyles.root}>
            <Container maxWidth="xl">
                <Grid container spacing={6}>

                    <Grid item xs={12} md={4}>
                        <Box sx={footerStyles.logoContainer}>
                            <SportsEsports sx={footerStyles.logoIcon} />
                            <Typography variant="h5" sx={footerStyles.logoText}>
                                LuckyChange
                            </Typography>
                        </Box>
                        <Typography variant="body2" sx={footerStyles.description}>
                            Welcome to Lucky Change your ultimate destination for free social casino games that promise endless excitement and entertainment.
                        </Typography>
                    </Grid>


                    <Grid item xs={6} md={2}>
                        <Typography variant="h6" sx={footerStyles.sectionTitle}>
                            Navigation
                        </Typography>
                        <Box component="nav">
                            {['Game', 'Leaderboard', 'Charities', 'About'].map((item) => (
                                <Link
                                    key={item}
                                    component={RouterLink}
                                    to={item === 'Game' ? '/game' : '#'}
                                    variant="body2"
                                    sx={footerStyles.navLink}
                                >
                                    {item}
                                </Link>
                            ))}
                        </Box>
                    </Grid>


                    <Grid item xs={6} md={2}>
                        <Typography variant="h6" sx={footerStyles.sectionTitle}>
                            Legal
                        </Typography>
                        {['Terms', 'Privacy', 'Responsible Play', 'FAQ'].map((item) => (
                            <Link
                                key={item}
                                href="#"
                                variant="body2"
                                sx={footerStyles.navLink}
                            >
                                {item}
                            </Link>
                        ))}
                    </Grid>


                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" sx={footerStyles.sectionTitle}>
                            Connect With Us
                        </Typography>
                        <Box sx={footerStyles.socialContainer}>
                            {[
                                { icon: <Facebook />, name: 'Facebook' },
                                { icon: <Twitter />, name: 'Twitter' },
                                { icon: <Instagram />, name: 'Instagram' }
                            ].map((social) => (
                                <IconButton
                                    key={social.name}
                                    sx={footerStyles.socialIcon}
                                >
                                    {social.icon}
                                </IconButton>
                            ))}
                        </Box>
                    </Grid>
                </Grid>


                <Box sx={footerStyles.bottomSection}>
                    <Typography variant="body2" sx={footerStyles.copyrightText}>
                        © {new Date().getFullYear()} LuckyChange. All rights reserved.
                        <Box component="span" sx={footerStyles.separator}>•</Box>
                    </Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default Footer;
