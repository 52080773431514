import { useState } from 'react';
import "../styles/Roulette.css";

const numbers = Array.from({ length: 37 }, (_, i) => i);
const colors = numbers.map(n => (n === 0 ? 'green' : n % 2 === 0 ? 'black' : 'red'));

const Roulette = () => {
    const [spinning, setSpinning] = useState(false);
    const [result, setResult] = useState<number | null>(null);
    const [score, setScore] = useState(0);

    const [animateScore, setAnimateScore] = useState(false);

    const spinWheel = () => {
        if (spinning) return;
        setSpinning(true);
        setTimeout(() => {
            const randomNumber = numbers[Math.floor(Math.random() * numbers.length)];
            setResult(randomNumber);

            let newScore = score;
            if (randomNumber === 0) {
                newScore += 50;
            } else if (colors[randomNumber] === 'red' || colors[randomNumber] === 'black') {
                newScore += 10;
            } else {
                newScore += 5;
            }

            setScore(newScore);
            setAnimateScore(true);
            setTimeout(() => setAnimateScore(false), 300);
            setSpinning(false);
        }, 3000);
    };


    return (
        <div className="roulette-container"
             style={{
                 backgroundImage: "url('/assets/Roulette2.png')",
                 backgroundSize: "cover",
                 backgroundPosition: "center",
                 backgroundRepeat: "no-repeat"
             }}>
            <div className={animateScore ? "scoreboard score-updated" : "scoreboard"}>
                <p>Score: {score}</p>
                <p>Last Result: {result !== null ? result : '?'}</p>
            </div>
            <div className={`roulette-wheel ${spinning ? 'spinning' : ''}`}>
                <div className="result" style={{backgroundColor: result !== null ? colors[result] : 'transparent'}}>
                    {result !== null ? result : '?'}</div>
            </div>
            <button className="spin-button" onClick={spinWheel} disabled={spinning}>
                {spinning ? 'Spinning...' : 'Spin the Wheel'}
            </button>
        </div>
    );
};

export default Roulette;
