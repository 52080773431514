import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: '#00FFA5',
        },
        secondary: {
            main: '#0F172A',
        },
        background: {
            default: '#0F172A',
        },
    },
    typography: {
        h1: {
            fontSize: '3rem',
            fontWeight: 700,
            color: '#00FFA5',
        },
        h5: {
            fontSize: '1.25rem',
            color: '#94A3B8',
        },
        h3: {
            fontSize: '2.5rem',
            fontWeight: 700,
            color: '#FFFFFF',
        },
        h4: {
            fontSize: '2rem',
            fontWeight: 600,
            color: '#FFFFFF',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    padding: '10px 30px',
                },
            },
        },
    },
});

export default theme;
